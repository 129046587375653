import { useEffect, useState } from "react";
import { MenuItemType } from "@app/components/Common/atoms/Sidebar/Sidebar.types";

import Sidebar from "@app/components/Common/atoms/Sidebar/Sidebar";
import { getItem } from "@app/components/Common/atoms/Sidebar/Sidebar.utils";
import { PublicServiceSpecificationDef } from "@app/@generated";
import {
  ApartmentOutlined,
  FileOutlined,
  FileProtectOutlined,
  ProfileOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export type ServicesSidebarType = {
  services: PublicServiceSpecificationDef[] | [];
  onSelectMenu: (item: MenuItemType) => void;
};
const ServicesSidebar = ({ services, onSelectMenu }: ServicesSidebarType) => {
  const [items, setItems] = useState<MenuItemType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (services) {
      const grouped: MenuItemType[] = [];
      services.map(item => {
        if (item.attributes && item.id)
          grouped.push(
            getItem(item.attributes.title, item.id, null, [
              getItem(
                t("servicesApi.title"),
                `${item.id}-/-api`,
                <FileOutlined />
              ),
              getItem(
                t("servicesSchema.title"),
                `${item.id}-/-schema`,
                <ApartmentOutlined />
              ),
              getItem(
                t("servicesApiJourney.title"),
                `${item.id}-/-apiJourney`,
                <ApartmentOutlined />
              ),
              getItem(
                t("servicesTools.title"),
                `${item.id}-/-tools`,
                <SettingOutlined />
              ),
              getItem(
                t("servicesOperationsTools.title"),
                `${item.id}-/-operations`,
                <SettingOutlined />
              ),
              getItem(
                t("servicesEnvironments.title"),
                `${item.id}-/-environments`,
                <ProfileOutlined />
              ),
              getItem(
                t("servicesEvents.title"),
                `${item.id}-/-events`,
                <FileProtectOutlined />
              ),
            ])
          );
      });

      setItems(grouped);
    }
  }, [services]);

  return <Sidebar items={items} onSelectMenu={onSelectMenu} />;
};

export default ServicesSidebar;
