import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";
import { DiagramWrapper } from "@app/components/DiagramWrapper/DiagramWrapper";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { servicesAPIOperationsApi } from "@app/features/services/api/services.api";
import {
  deserializeUsedLinks,
  deserializeOperationsLinks,
  deserializeOperations,
} from "@app/utils/operationLinks";

import { logger } from "@app/utils/logger";
import {
  LinkOperationType,
  Operation,
  OperationsType,
} from "@app/types/api.types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServiceSchema = (props: any) => {
  const [operationsLinks, setOperationsLinks] =
      useState<null | Array<LinkOperationType>>(null);
  const [operations, setOperations] =
      useState<OperationsType<Operation> | null>(null);
  const [usedOperations, setUsedOperations] = useState<Array<string>>([]);

  const { id } = props.data;
  const [isLoading, setLoadingStatus] = useState<boolean>(true);
  const getOperationsLinks = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
          await servicesAPIOperationsApi.servicesServiceIdApiOperationLinksGet({
            serviceId: id,
          });

      const links = deserializeOperationsLinks(data.data);
      setOperationsLinks(links);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const getOperations = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
          await servicesAPIOperationsApi.servicesServiceIdApiOperationsGet({
            serviceId: id,
          });
      const used = deserializeUsedLinks(data.data);
      const operations = deserializeOperations(data.data);
      setUsedOperations(used);
      setOperations(operations);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };
  useEffect(() => {
    getOperations();
    getOperationsLinks();
  }, [id]);
  return (
      <div id="wrap-api-operations">
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
            <DiagramWrapper
                isEditable={false}
                nodes={operations}
                nodesUsed={usedOperations}
                operationsLinks={operationsLinks}
                container="wrap-api-operations"
            />
        )}
      </div>
  );
};

export default ServiceSchema;
