import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  Controls,
  MiniMap,
  Background,
  BackgroundVariant,
} from "@xyflow/react";

import "@xyflow/react/dist/style.css";
import { useCallback, useEffect } from "react";
import { LinkOperationType } from "@app/types/api.types";
import { cutString } from "@app/utils/common";
import { METHOD_COLORS } from "@app/components/GraphOperationItem/GraphOperationItem.constants";

interface APIJourneyProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialNodes?: any;
  nodesUsed?: null | string[];
  operationsLinks?: null | LinkOperationType[];
}

interface IEdges {
  type: string;
  id: string;
  source: string;
  target: string;
}

interface ItransformedNodes {
  id: string;
  type: string;
  position: { x: number; y: number };
  data: { label: string };
  style: {
    backgroundColor: string;
    width: number;
    height: number;
    fontSize: number;
    borderColor: string;
  };
}

const transformToEdge = (links: LinkOperationType[]) => {
  const edges: IEdges[] = [];

  links?.map((link, index) => {
    edges.push({
      type: "smoothstep",
      id: index.toString(),
      source: link.inwardApiOperation,
      target: link.outwardApiOperation,
    });
  });

  return edges;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformNodes = (nodes: any, nodesUsed?: null | string[]) => {
  const transformedNodes: ItransformedNodes[] = [];
  if (!nodes) return transformedNodes;
  Object.keys(nodes).map((key: string) => {
    const node = nodes[key];
    if (nodesUsed?.includes(node.id)) {
      transformedNodes.push({
        id: node.id,
        type: "deletable",
        position: { x: node.xCoordinate || 0, y: node.yCoordinate || 0 },
        data: {
          label: `${node.httpMethod || "GET"} ${cutString(node.path, 20)}`,
        },
        style: {
          backgroundColor: METHOD_COLORS[node.httpMethod || "GET"].background,
          width: 290,
          height: 45,
          fontSize: 14,
          borderColor: METHOD_COLORS[node.httpMethod || "GET"].border,
        },
      });
    }
  });

  return transformedNodes;
};

const APIJourney = ({
  initialNodes,
  nodesUsed,
  operationsLinks,
}: APIJourneyProps) => {
  useEffect(() => {
    if (nodesUsed) {
      setNodes(transformNodes(initialNodes, nodesUsed));
    }
  }, [nodesUsed]);

  const [nodes, setNodes, onNodesChange] = useNodesState(
    transformNodes(initialNodes, nodesUsed)
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    operationsLinks ? transformToEdge(operationsLinks) : []
  );

  const onConnect = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (params: any) => setEdges(eds => addEdge(params, eds)),
    [setEdges]
  );

  return (
    <div style={{ width: "90%", height: "calc(100vh - 340px)" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
      >
        <Controls />
        <MiniMap />
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
    </div>
  );
};

export default APIJourney;
